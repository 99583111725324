.add-coupon-page-main {
  .discount-value {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }

  ::placeholder {
    color: #8c8c8c;
  }
}
.showReasonModal {
  .modal-content {
    padding: 2em;
    .couponModal-btn {
      align-self: flex-end;
      .cancel--btn {
        background-color: transparent;
        border-color: $special-admin;
        text-align: center;
      }
      .remove--btn {
        text-align: center;
      }
    }
  }
}
.coupon-list {
  ul {
    display: flex;
    list-style: none;
    justify-content: flex-start;
    padding: 0px;
    margin: 0px;
    gap: 1em;
  }
}
.coupon-management-page_main {
  .coupon-title {
    font-size: 20px;
    margin-top: 0.8rem;
  }
  table {
    tr {
      .setLabel {
        background: $gray-700;
        padding: 10px;
        margin-left: 1em;
      }
      .natureLabel,
      .couponLabel {
        text-transform: capitalize;
      }
    }
  }
}
.couponModal {
  .modal-dialog {
    max-width: min-content;
  }
  .modal-content {
    padding: 1em;
  }
  .modal-title {
    color: $input-value;
  }
  .btn-close {
    --bs-btn-close-bg: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23be210b%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>');
  }
  .modal-body {
    height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .couponListBox {
      .coupon-item {
        position: relative;
        margin-bottom: 30px;
        padding: 25px;
        color: $input-value;
        background: linear-gradient(135deg, $gray-700 0%, darken($gray-700, 10%) 100%);
        border-radius: 20px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;

        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 40px;
          height: 40px;
          background: $brand-primary;
          border-radius: 50%;
        }

        &::before {
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
        }

        &::after {
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          transform: translateY(-5px) rotate(1deg);
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
        }

        &::before {
          content: '✂';
          font-size: 24px;
          color: $input-value;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .coupon-header {
        .coupon_details {
          display: flex;
          align-items: flex-start;
          gap: 20px;

          .coupon-icon {
            font-size: 40px;
            color: $brand-accent;
            transform: rotate(-15deg);
          }

          .coupon_name {
            flex-grow: 1;

            .coupons_name {
              font-weight: bold;
              font-size: 22px;
              color: $input-value;
              margin-bottom: 10px;
              width: 100%;
              padding-right: 100px;
              display: block;
              text-transform: capitalize;
              text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
            }

            .coupons_code {
              display: inline-block;
              font-size: 10px;
              font-weight: bold;
              color: $brand-accent;
              background: rgba($brand-accent, 0.1);
              border: 2px dashed $brand-accent;
              padding: 5px 10px;
              border-radius: 15px;
              text-transform: uppercase;
              letter-spacing: 1px;
              position: relative;
              overflow: hidden;

              &::after {
                content: '';
                position: absolute;
                top: -50%;
                left: -50%;
                width: 200%;
                height: 200%;
                background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
                transform: rotate(30deg);
                animation: shimmer 3s infinite;
              }

              @keyframes shimmer {
                0% {
                  transform: translateX(-100%) rotate(30deg);
                }
                100% {
                  transform: translateX(100%) rotate(30deg);
                }
              }
            }
          }
        }
      }

      .coupon_step_to_avail {
        font-size: 14px;
        margin-top: 10px;
        padding: 10px 0;
        white-space: pre-wrap;
        border-top: 1px dashed rgba($input-value, 0.3);
      }

      .apply-coupon-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: $brand-accent;
        color: $input-value;
        border: none;
        border-radius: 25px;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          background-color: darken($brand-accent, 10%);
          transform: translateY(-2px);
          box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  .show-more {
    font-size: 14px;
    color: $brand-accent;
    cursor: pointer;
    margin-left: 5px;
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s ease;

    &:hover {
      color: darken($brand-accent, 15%);
    }
  }
}

.couponSelection {
  display: flex;
  margin-top: 1.5rem;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 1rem;
  .coupon-selection {
    background-color: $gray-700;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    padding: 1em;
    &:hover {
      transform: translateY(-5px);
    }

    .selected-coupon {
      display: flex;
      flex-direction: row;
      flex: auto;
      align-items: center;
      gap: 10px;

      .coupon-code-sel {
        text-transform: uppercase;
        border: 1px solid $brand-accent;
        padding: 5px 15px;
        border-radius: 10px;
        letter-spacing: 2px;
        display: block;
        width: auto;
        font-size: 12px;
        color: $brand-accent;
        font-weight: bold;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
      .remove-coupon-sel {
        padding: 5px;
        border-radius: 50%;
        background-color: $input-value; // soft red for delete button
        color: $brand-accent; // deep red for contrast
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.2s ease;
      }
    }

    .use-coupon-btn {
      cursor: pointer;
      border: 2px dashed $brand-accent; // change dotted to dashed for style
      padding: 10px 20px;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $brand-accent;
      border-radius: 8px;
      display: flex;
      align-items: center;
      transition: background-color 0.3s ease;
      margin-bottom: 0px;

      &:hover {
        background-color: $input-value; // deeper blue on hover
      }
    }
  }
}

.disabled-coupon {
  opacity: 0.6;
  pointer-events: none;
}

.coupon-not-applicable {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 5px;
}

.apply-coupon-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
