.add-charging-station-page__main,
.add-charger-page__main {
  .station-form--card,
  .charger-form--card {
    .card-body {
      padding: 1.5rem 2.5rem;
    }
    .station-form--block,
    .charger-form--block {
      padding: 1rem 0;
      .form-input-group,
      .select-input-form-group {
        .pre-text-input {
          padding-left: 2.5rem !important;
        }
      }
      .amenity-group-block {
        margin-bottom: 1.5rem;
        .amenities-list--block {
          display: block;
          .amenities-list--item {
            float: left;
            margin-right: 3rem;
            margin-top: 0.5rem;
          }
        }
      }
      .checkbox-input--group {
        margin-bottom: 1.5rem;
        .checkbox-input-label {
          font-size: 0.95rem;
          margin-bottom: 0.7rem;
          color: $input-label;
        }
      }
      .parking-checkbox-input__block {
        margin-top: 3rem;
        .otp-checkbox {
          display: flex;
          justify-content: flex-start;
        }
        .parking--label {
          font-size: 1rem;
          margin-bottom: 0.7rem;
          color: $input-label;
          font-weight: 500;
          margin-right: 1rem;
        }
      }
      .show-on-mobile-field-main--block {
        margin-top: 2rem;
        .show-on-mobile-checkbox-input__block {
          .show-on-mobile--label {
            font-size: 1rem;
            margin-bottom: 0.7rem;
            color: $input-label;
            font-weight: 500;
            margin-right: 1rem;
          }
        }
      }
      .time-block__field-inputs,
      .plug-block__field-inputs,
      .tag-block__field-inputs {
        .charger-plug--item {
          margin-bottom: 1.5rem;
          border-bottom: 1px solid #666666;
        }
        .field--btn {
          border-color: transparent;
          background-color: transparent;
          color: $brand-accent;
          font-size: 1rem;
          padding: 0;
          font-weight: 600;
          display: flex;
          align-items: center;
          &:focus,
          &:active {
            box-shadow: none;
          }
          .plus--icon {
            margin-right: 0.2rem;
          }
        }
      }
    }
    .save-draft-btn--block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .save-btn {
        padding: 0.5rem 0.8rem;
        background-color: $button-primary;
        border-color: $button-primary;
      }
      .draft--btn {
        background: $button-secondary;
        border-color: $button-secondary;
        color: $button-secondary-text;
      }
    }
    input[type='date'] {
      padding: 0.82rem;
    }
  }
}
.station-btn-config{
  background-color: $button-primary;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 10px 15px;
}
.station-checkbox-config{
  display: flex;
  // justify-content: space-around;
  gap: 10px;
  margin-top: 1rem;
}