.charger-detail-page__main {
  .charger-profileInfo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    .charger-box-main {
      display: flex;
      justify-content: space-around;
      width: 100%;
      // background-color: green;
      .charger-box {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 0rem;
        .charger-icon {
          display: flex;
          padding: 0.5rem 1rem;
          background: $status-charger-icon-bg;
          height: 4rem;
          width: 4rem;
          color: $brand-accent;
          border-radius: 15px;
          .plug--icon {
            height: 3rem;
            width: 2rem;
          }
        }
        .charger-profileInfo-wrapper {
          margin-left: 1rem;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          .charger--name {
            display: flex;
            align-items: center;
            font-size: 1.625rem;
            font-weight: 500;
          }
        }
      }
      .charger-status-action__block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 5px;
        // background-color: $semantic-info;
        margin-left: 0px;
        .charger-status--block {
          .charger--status {
            padding: 0.5rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            .dot--icon {
              display: inline-flex;
              height: 1.5rem;
              width: 1.5rem;
            }
          }
        }
        .charger-action--block {
          margin-left: 2rem;
          .charger-action--btn {
            border-radius: 5px;
            border: none;
            font-weight: 400;
            font-size: 0.85rem;
            padding: 0.5rem 1.7rem;
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
    .charger-meta-info {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .copy-clipboard__block {
        margin-right: 10px;
        .copy-btn {
          border: none;
          background: $special-billing;
          color: $input-value;
          border-radius: 10px;
          padding: 10px;
          width: 50px;
          &:hover {
            background: $gray-600;
          }
          .copy--icon {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
        
      }
      .qr-code--btn {
        margin-right: 20px;
        padding: 5px;
        background: $special-billing;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
        width: 50px;
        &:hover {
          background: $gray-600;
        }
      }
    }

    .charger-dropdown_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .charger-dropdown-icon > svg {
        font-size: 1.7rem;
        // background-color: red;
      }
      .item
      {
        display: flex;
        // background-color: red;
        justify-content: flex-start;
      }
      .item:hover svg
      {
        transform: scale(1.2);
      }
      .delete--charger {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          font-size: 1.2rem;
          transition: 0.2s;
        }
        .delete-charger-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $brand-accent;
          padding-left: 1rem;
        }
      }

      .edit--charger {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          font-size: 1.2rem;
        }

        .edit-charger-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $input-value;
          padding-left: 1rem;
        }
      }
    }

    .d-flex {
      margin-left: auto;
    }
  }

  .charger-detail__one {
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .charger-detail__two {
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .charger-data-main {
    background: $brand-primary;
    border: 1px solid $tab-active-color;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
    .charger-data-inner {
      padding: 1rem;
      .charger-OEM {
        color: $gray-200;
        margin-right: 0.4rem;
      }
    }
    .charger-data-main_inner {
      padding: 1rem;
      .Charger-speed {
        color: $gray-200;
        margin-right: 0.4rem;
      }
    }
  }
  .charger-box-pages_main {
    .charger-box-pages_inner {
      .opration-timing__block {
        background: $brand-primary;
        border: 1px solid $tab-active-color;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0;
        padding: 1.5rem 1.2rem;
        margin-bottom: 1rem;
        .Charger-operation_timing {
          margin-bottom: 1.5rem;
          .charger-operation {
            color: $gray-200;
          }
        }
        .operation-timing__inner {
          display: flex;
          align-items: center;
          .watch--icon {
            margin-right: 0.5rem;
            .watch-icon {
              color: $semantic-info;
              height: 1.5rem;
              width: 1.5rem;
            }
          }
          .oprational-timing-info {
            font-size: 0.95rem;
            .dot-icon {
              margin: 0 0.2rem;
            }
          }
        }

        .charger-watch_icon {
          color: $semantic-info;
        }
      }
      .charger-box_block {
        background: $brand-primary;
        border: 1px solid $tab-active-color;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 1rem;
        padding: 1.5rem 1.2rem;
        .charger-time {
          display: flow-root;
          .charger--status {
            margin-bottom: 1rem;
            .dot--icon {
              height: 1.5rem;
              width: 1.5rem;
            }
          }
        }
        .charging-time_left {
          margin-bottom: 1rem;
          .charger-Tltc {
            color: $gray-200;
            margin-right: 0.5rem;
            .charger-watch_icon {
              margin-right: 0.7rem;
              .watch_icon {
                color: $semantic-info;
                height: 1.5rem;
                width: 1.5rem;
              }
            }
          }
        }
        .charger-energy {
          .charger-ectn {
            color: $gray-200;
            margin-right: 0.5rem;
            .charger-enery_icon {
              margin-right: 0.7rem;
              .enery_icon {
                color: $semantic-info;
                height: 1.5rem;
                width: 1.5rem;
              }
            }
          }
        }
      }
    }
    .charger-data-page-main {
      .charger-colom-inner {
        background: $brand-primary;
        border: 1px solid $tab-active-color;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0;
        padding: 1.5rem 1.2rem;
        margin-bottom: 1rem;
        .charger-pluge {
          color: $gray-200;
        }
        .charger-four {
          display: flex;
          justify-content: space-between;
          .charger-four-btn {
            padding: 0.2rem 0.4rem;
            background-color: $status-breathing-red;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: $input-value;
          }
        }
      }
      .charger-comments_notes {
        background: $brand-primary;
        border: 1px solid $tab-active-color;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0;
        padding: 1.5rem 1.2rem;
        display: flex;
        flex-direction: column;
        .charger-Comment {
          color: $gray-200;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .loader--block {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/** Charger PlugeInfo component */
.charger-name_icon-data {
  padding: 1rem 0;
  .charger-name--icon {
    background: $brand-primary;
    border: 1px solid $tab-active-color;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.1rem 1.5rem;
    display: flex;
    align-items: center;
    .charger-round_icon {
      margin-right: 0.7rem;
      svg {
        color: $semantic-info;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

//QRCode-Modal
.qr-code-modal--main {
  .qr-code__block {
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 2px solid $tab-active-color;
    .qr-code-name {
      font-size: 1.25rem;
    }
    .qr-code-close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: $light-table-header;
      }
    }
  }
  .qrCode--img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    .empty-qrCode {
      text-align: center;
      padding: 1rem;
    }
  }
  .qrCode--button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0;
  }
}
